<template class="text_google">
  <v-app>
    <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          @click.stop="
            exportToExcel(
              'exportContent',
              'แบบฟอร์มกรอกคะแนน สอบคัดเลือก ผู้อำนวยการ'
            )
          "
          class="title elevation-0"
          color="grey darken-1"
          x-large
        >
          <v-icon>mdi-file-excel</v-icon>&nbsp; Export to CSV
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>

    <div id="exportContent" style="mso-page-orientation: landscape;">
      <div class="page">
        <table class="table" border="1" width="100%">
          <thead>
            <tr>
              <td class="regular16 pa-1 text-center">ปี</td>
              <td class="regular16 pa-1 text-center">ครั้งที่</td>
              <td class="regular16 pa-1 text-center">ลำดับสมัคร</td>
              <td class="regular16 pa-1 text-center">รหัสบัตรประชาชน</td>
              <td class="regular16 pa-1 text-center">ชื่อ-นามสกุล</td>
              <td class="regular16 pa-1 text-center">สถานศึกษา</td>
              <td class="regular16 pa-1 text-center">ภาค ก (10 คะแนน)</td>

              <td class="regular16 pa-1 text-center">
                ภาค ข 1.1 (10 คะแนน) สอจ.
              </td>
              <td class="regular16 pa-1 text-center">
                ภาค ข 1.2 (25 คะแนน) สอจ.
              </td>
              <td class="regular16 pa-1 text-center">
                ภาค ข 1.3 (5 คะแนน) สอจ.
              </td>

              <td class="regular16 pa-1 text-center">
                ภาค ข 1.1 (10 คะแนน) ส่วนกลาง
              </td>
              <td class="regular16 pa-1 text-center">
                ภาค ข 1.2 (25 คะแนน) ส่วนกลาง
              </td>
              <td class="regular16 pa-1 text-center">
                ภาค ข 1.3 (5 คะแนน) ส่วนกลาง
              </td>

              <td class="regular16 pa-1 text-center">
                ภาค ข 1.1 (10 คะแนน) กลั่นกรอง
              </td>
              <td class="regular16 pa-1 text-center">
                ภาค ข 1.2 (25 คะแนน) กลั่นกรอง
              </td>
              <td class="regular16 pa-1 text-center">
                ภาค ข 1.3 (5 คะแนน) กลั่นกรอง
              </td>

              <td class="regular16 pa-1 text-center">
                ภาค ข 2 (20 คะแนน) ส่วนกลาง
              </td>
              <td class="regular16 pa-1 text-center">
                ภาค ค (30 คะแนน) ส่วนกลาง
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sedirector_apps" :key="item.id">
              <td class="text-center regular16 pa-1 text-center red--text">
                {{ item.sedirector_app_year }}
              </td>
              <td class="text-center regular16 pa-1 text-center red--text">
                {{ item.sedirector_app_time }}
              </td>
              <td class="text-center regular16 pa-1 text-center red--text">
                {{ item.sedirector_app_idno }}
              </td>

              <td class="text-center regular16 pa-1 text-center red--text">
                {{ item.sedirector_app_idcard }}
              </td>
              <td class="text-center regular16 pa-1 red--text">
                {{ item.fristnames }}
              </td>
              <td class="text-center regular16 pa-1 red--text">
                {{ item.user_firstname }}
              </td>

              <td
                class="text-center regular16 pa-1"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_pvcscore_A }}
              </td>

              <td class="text-center regular16 pa-1 red--text">
                {{ item.sedirector_app_pvcscore_B }}
              </td>
              <td class="text-center regular16 pa-1 red--text">
                {{ item.sedirector_app_pvcscore_B_2 }}
              </td>
              <td class="text-center regular16 pa-1 red--text">
                {{ item.sedirector_app_pvcscore_B_3 }}
              </td>

              <td
                class="text-center regular16 pa-1"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_ipascore_B_a1 }}
              </td>
              <td
                class="text-center regular16 pa-1"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_ipascore_B_a2 }}
              </td>
              <td
                class="text-center regular16 pa-1"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_ipascore_B_a3 }}
              </td>

              <td
                class="text-center regular16 pa-1"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_commitscore_B_a1 }}
              </td>
              <td
                class="text-center regular16 pa-1"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_commitscore_B_a2 }}
              </td>
              <td
                class="text-center regular16 pa-1"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_commitscore_B_a3 }}
              </td>

              <td
                class="text-center regular16 pa-1"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_pvcscore_B_4 }}
              </td>
              <td
                class="text-center regular16 pa-1"
                style="background-color: aqua;"
              >
                {{ item.sedirector_app_score_c1 }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    valid: true,
    sedirector_apps: []
  }),

  async mounted() {
    await this.sedirector_appQueryAll();
    await this.exportToExcel(
      "exportContent",
      "แบบฟอร์มกรอกคะแนน สอบคัดเลือก ผู้อำนวยการ"
    );
    await this.close_window();
  },

  methods: {
    async sedirector_appQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_ptype: 3,
          sedirector_app_time: this.$route.query.times,
          sedirector_app_year: this.$route.query.years,
          sedirector_app_pvc_approve: "pass"
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;
    },

    async close_window() {
      close();
    },
    exportToExcel(element, filename = "Director") {
      let table = document.getElementById(element);
      let tableHTML = table.outerHTML;

      // Include the CSS styles in the Excel file
      let style = `
                        <style>
  @import url(https://fonts.googleapis.com/css?family=Prompt:400,300|Roboto:400,300&subset=latin,thai);
  
  body {
    font-family:"TH SarabunPSK";
    font-size: 16px;
    font-weight: 250;
  }  
  
 </style>
            `;

      let html = `
                <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
                <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>Sheet1</x:Name>
                <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
                ${style}
                </head>
                <body>
                    ${tableHTML}
                </body>
                </html>
            `;

      // Create a Blob object for the data
      let blob = new Blob([html], { type: "application/vnd.ms-excel" });

      // Create a download link
      let downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${filename}.xls`;

      // Trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  },
  props: {
    source: String
  }
};
</script>

<style>
@import url(
  https://fonts.googleapis.com/css?family=Prompt:400,
  300|Roboto:400,
  300&subset=latin,
  thai
);

body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.textAlignVer {
  display: block;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  width: 20px;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 5;
}

u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 40.7cm;
  min-height: 21cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 0.5cm;
  padding-right: 0.5cm;
  padding-bottom: 0.5cm;
  padding-left: 0.5cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
      height: 247mm;
    }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    border: initial;
    border-radius: initial;
    /* width: initial; */
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding: initial;
    page-break-after: always;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
        font-size: 18px;
        font-weight: bold;
      }
      .regular12 {
        font-size: 12px;
      }
      .blod12 {
        font-size: 12px;
        font-weight: bold;
      }
      .blackRegula8 {
        font-size: 8px;
      } */
  .noprint {
    display: none;
  }
}
</style>
